/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pathNamePopped } from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice.js";
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice.js";
import {
  fetchContractsByProperty,
  fetchMoveOutRequestsByProperty,
  getTenancyContracts,
  getMoveOutRequests,
} from "../../../../setup/store/slices/contractSlice";
import {
  fetchExistingTenantsByProperty,
  fetchNewTenantsByProperty,
  fetchOldTenantsByProperty,
  fetchTransitionedTenantsByProperty,
  getExistingTenants,
  getNewTenants,
  getOldTenants,
  getTransitionedTenants,
  fetchPropertyDetailByID,
  getPropertyDetail,
  fetchCancelledTenantsByProperty,
  getCancelledTenants,
} from "../../../../setup/store/slices/propertySlice";
import { fetchCanInviteTenant } from "../../../../setup/store/slices/unitSlice.js";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global.js";
import TabButton from "../../../ui/other/TabButton/TabButton";
import ExistingTenants from "./sub-component/ExistingTenants";
import TransitionedTenants from "./sub-component/TransitionedTenants";
import PreviousTenants from "./sub-component/PreviousTenants";
import NewTenants from "./sub-component/NewTenants";
import MoveOut from "./sub-component/MoveOut";
import Renewals from "./sub-component/Renewals";
import Requests from "./sub-component/Requests";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CancelledTenants from "./sub-component/CancelledList.jsx";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ManageTenants = (props) => {
  /* Props destructuring starts */
  const { tenants, setList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [searchQ, setSearchQ] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [canInviteRes, setCanInviteRes] = useState(false);
  const [permissionsFeched, setPremissionsFetched] = useState(false);
  const [buttonLists, setButtonLists] = useState([]);
  const [message, setMessage] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { propertyId, tab } = useParams();
  const contracts = useSelector(getTenancyContracts);
  const moveOutRequests = useSelector(getMoveOutRequests);
  const existingTenants = useSelector(getExistingTenants);
  const transitionedTenants = useSelector(getTransitionedTenants);
  const newTenants = useSelector(getNewTenants);
  const oldTenants = useSelector(getOldTenants);
  const cancelledTenants = useSelector(getCancelledTenants);

  const propertyDetail = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const mgmtModel = propertyDetail?.mgmt_model;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Extracting tenant details from given object
  const extractTenant = (item) => {
    return item?.application?.application_details?.tenant;
  };

  // Extracting unit details from given object
  const extractUnit = (item) => {
    return item?.application?.application_details?.unit;
  };
  const onTabSelect = async (key) => {
    // setCurrentKey(key);
    const tabPath = pathname.split("/");
    tabPath.pop();
    navigate(tabPath.join("/") + "/" + key);
    setCurrentPage(1);
  };

  const handleAddingTenant = async () => {
    if (canInviteRes?.can_invite_tenant) {
      // navigate(pathNamePopped(pathname, 1) + "/add/invite");
      navigate(`${pathNamePopped(pathname, 1)}/add/invite`, {
        state: {
          pathname: pathname,
        },
      });
    } else {
      setMessage(canInviteRes?.message);
      setShowErrorModal(true);
    }
  };

  const getCanInviteTenant = async () => {
    setPremissionsFetched(false);
    try {
      const result = await dispatch(
        fetchCanInviteTenant({ property_id: propertyId })
      );
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
        case GLOBAL.FULFILLED:
          setCanInviteRes(result.payload);
          setPremissionsFetched(true);
          break;
      }
    } catch (e) {
      console.error("error", e);
      setPremissionsFetched(true);
    }
  };

  const refreshNewTenant = () => {
    dispatch(
      fetchNewTenantsByProperty({
        property_id: propertyId,
        page: currentPage,
      })
    );
  };

  const refreshTransitionedTenant = () => {
    dispatch(
      fetchTransitionedTenantsByProperty({
        property_id: propertyId,
        page: currentPage,
      })
    );
  };

  const fetchPropertyDetail = async () => {
    // Backend response. Try, Catch
    try {
      await dispatch(setLoading(true));
      const result = await dispatch(
        fetchPropertyDetailByID({ property_id: propertyId })
      );
      await dispatch(setLoading(false));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (searchQ !== "") {
      const sQ = searchQ.toLocaleLowerCase();
      const newT = tenants?.new.filter((t, index) => {
        return (
          extractTenant(t).first_name.toLowerCase().includes(sQ) ||
          extractTenant(t).last_name.toLowerCase().includes(sQ) ||
          extractUnit(t).unit_name.toLowerCase().includes(sQ)
        );
      });

      setList((prevState) => ({ ...prevState, new: newT }));
    } else {
      setList(tenants);
    }
  }, [searchQ]);

  useEffect(() => {
    (async () => {
      await dispatch(
        fetchExistingTenantsByProperty({
          property_id: propertyId,
          page: currentPage,
        })
      );
    })();
  }, [currentPage]);

  useEffect(() => {
    switch (tab) {
      case "existing":
        dispatch(
          fetchExistingTenantsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      case "transitioned":
        dispatch(
          fetchTransitionedTenantsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;
      case "new":
        dispatch(
          fetchNewTenantsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;
      case "previous-tenants":
        dispatch(
          fetchOldTenantsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      case "cancelled":
        dispatch(
          fetchCancelledTenantsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      case "requests":

      case "renewals":
        dispatch(
          fetchContractsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      case "move-out":
        dispatch(
          fetchMoveOutRequestsByProperty({
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      default:
        break;
    }
  }, [tab, currentPage]);

  useEffect(() => {
    getCanInviteTenant();
    fetchPropertyDetail();
  }, []);

  useEffect(() => {
    if (propertyDetail) {
      if (propertyDetail?.mgmt_model === 1) {
        setButtonLists([
          { key: "existing", label: "Existing" },
          { key: "new", label: "New" },
          { key: "previous-tenants", label: "Previous" },
          { key: "cancelled", label: "Cancelled" },
        ]);
      } else {
        setButtonLists([
          { key: "existing", label: "Existing" },
          { key: "transitioned", label: "Tranistioned" },
          { key: "new", label: "New" },
          { key: "previous-tenants", label: "Previous" },
          // { key: "requests", label: "Requests" },
          { key: "move-out", label: "Move Out" },
          { key: "renewals", label: "Renewals" },
          { key: "cancelled", label: "Cancelled" },
        ]);
      }
    }
  }, [propertyDetail]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="manage_tenants" />
      <div className="manage-tenants">
        <div className="heading">
          <h2 className="font_xxl fw_6">Tenants</h2>
          <p
            onClick={handleAddingTenant}
            className="font_l fw_6 d_block link"
            style={{ zIndex: "10" }}
          >
            + Add Tenant
          </p>
        </div>

        {/* <Searchbar query={searchQ} setQuery={setSearchQ} /> */}

        {/* TAB BUTTONS */}
        <TabButton
          className="manage-tenants-btn"
          buttonList={buttonLists}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "existing" ? (
          <ExistingTenants
            list={existingTenants?.data}
            totalCount={existingTenants?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "transitioned" ? (
          <TransitionedTenants
            list={transitionedTenants?.data}
            totalCount={transitionedTenants?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            refreshTransitionedTenant={refreshTransitionedTenant}
          />
        ) : tab === "new" ? (
          <NewTenants
            list={newTenants?.data}
            totalCount={newTenants?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            refreshNewTenant={refreshNewTenant}
          />
        ) : tab === "previous-tenants" ? (
          <PreviousTenants
            list={oldTenants?.data}
            totalCount={oldTenants?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "requests" ? (
          <Requests list={tenants?.requests} />
        ) : tab === "move-out" ? (
          <MoveOut
            list={moveOutRequests?.result}
            totalCount={moveOutRequests?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            mgmtModel={mgmtModel}
          />
        ) : tab === "renewals" ? (
          <Renewals
            list={contracts}
            totalCount={contracts?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <CancelledTenants
            list={cancelledTenants?.data}
            totalCount={cancelledTenants?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={message}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </>
  );
};
/* Component ends */

export default ManageTenants;
