import GLOBAL from "../../../../../setup/constants/global";

export const buttonList = [
  { key: GLOBAL.INVOICING.TENANTS, label: "Tenants" },
  { key: GLOBAL.INVOICING.PROPERTIES_INVOICES, label: "Properties Invoices" },
  { key: GLOBAL.INVOICING.PARTIES_INVOICES, label: "Parties Invoices" },
  // { key: GLOBAL.INVOICING.ADVERTISMENT, label: "Advertisment" },
];

export const toShowTheAddInvoiceOption = (tab, subTab, mgmt_model, role) => {
  if (mgmt_model === 0) {
    return true;
  }

  if (mgmt_model === 1) {
    if (tab === "Tenants" && subTab === "Others") {
      return false;
    } else if (
      tab === "Property Invoice" &&
      (subTab === "utilities" || subTab === "others") &&
      !role
    ) {
      return true;
    } else if (tab === "Parties Invoice" && role) {
      return true;
    }
  }
};
