/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUnitFilters } from "../../../../setup/store/slices/filterSlice";
import { fetchUnits, getUnits } from "../../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  EditGreen,
  FilterGreen,
  DocumentGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../ui/modal/FileViewerModal/FileViewerModal";
import UnitTableFilterModal from "../UnitTableFilterModal/UnitTableFilterModal";
import TextButton from "../../../ui/button/TextButton/TextButton";
import Searchbar from "../../../ui/other/Searchbar/Searchbar";
import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const UnitsTable = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [searchQ, setSearchQ] = useState("");
  const [filteredUnitsWithoutSearchQ, setFilteredUnitsWithoutSearchQ] =
    useState([]);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();

  // Redux Selector
  const units = useSelector(getUnits);

  const unitFilters = useSelector(getUnitFilters);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On clicking floorplan icon
  const onViewFloorPlan = (file) => {
    setFile(file);
    setShowFileViewer(true);
  };

  // On View Unit detail
  const onViewDetails = (unit) => {
    navigate(`${unit._id}`);
  };

  // On Edit Unit
  const onEdit = (unit) => {
    navigate(`${unit._id}/edit`);
  };

  const handleSearch = async () => {
    let payload = {
      unit_type: null,
      status: null,
      sub_status: null,
      query: searchQ,
    };

    console.log(payload, "()==()");
    // dispatch(setUnitFilters(payload));
    dispatch(
      fetchUnits(
        // { property_id: propertyId, page: currentPage }
        {
          property_id: propertyId,
          page: currentPage,
          filters: payload,
          // {
          //   status: null,
          //   sub_status: null,
          //   unit_type: null,
          //   query: "",
          // },
        }
      )
    );
  };

  const handleClear = () => {
    setSearchQ("");
    dispatch(
      fetchUnits(
        // { property_id: propertyId, page: currentPage }
        {
          property_id: propertyId,
          page: currentPage,
          filters: {
            status: null,
            sub_status: null,
            unit_type: null,
            query: "",
          },
        }
      )
    );
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all units on initial load
  useEffect(() => {
    dispatch(
      fetchUnits(
        // { property_id: propertyId, page: currentPage }
        {
          property_id: propertyId,
          page: currentPage,
          filters: {
            status: null,
            sub_status: null,
            unit_type: null,
            query: "",
          },
        }
      )
    );
  }, [currentPage]);

  // Filtering list according to the filter options activated
  useEffect(() => {
    if (units?.units) {
      let fUnits = units?.units;

      let fCount = 0;
      if (unitFilters?.unit_type && unitFilters?.unit_type !== undefined) {
        fUnits = fUnits.filter(
          (unit) => unit.is_active && unit.unit_type === unitFilters.unit_type
        );

        fCount += 1;
        // console.log(fUnits, "[111]", fCount);
      }
      if (unitFilters !== null && unitFilters?.status !== null) {
        fUnits = fUnits.filter((unit) => unit.is_active === unitFilters.status);
        fCount += 1;
        // console.log(fUnits, "[222]", fCount);
      }
      if (
        unitFilters !== null &&
        unitFilters?.sub_status !== null &&
        unitFilters?.sub_status !== undefined &&
        unitFilters?.status === 1
      ) {
        fUnits = fUnits.filter(
          (unit) =>
            unit.status.toLowerCase() === unitFilters.sub_status.toLowerCase()
        );
        fCount += 1;
        // console.log(fUnits, "[333]", fCount);
      }
      setFilteredUnits(fUnits);
      setFilteredUnitsWithoutSearchQ(fUnits);
      setFilterCount(fCount);
    }
  }, [units?.units, unitFilters]);

  // console.log(unitFilters)

  // Filtering list according to search query including the filter options activated
  // useEffect(() => {
  //   let filteredData = filteredUnitsWithoutSearchQ;
  //   console.log(filteredData, "filteredData");
  //   if (searchQ !== "") {
  //     filteredData = filteredUnitsWithoutSearchQ.filter((d) => {
  //       return (
  //         (d.is_active &&
  //           d.unit_name.toLowerCase().includes(searchQ.toLowerCase())) ||
  //         (!d.is_active &&
  //           d.unit_number.toLowerCase().includes(searchQ.toLowerCase())) ||
  //         (d.is_active &&
  //           d.unit_description.toLowerCase().includes(searchQ.toLowerCase()))
  //       );
  //     });
  //   }
  //   setFilteredUnits(filteredData);
  // }, [searchQ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-table-module-wrapper container_lg">
      {/* Filter Button */}
      <div className="filter-add-btn-wrapper">
        <div>
          <h2 className="font_xxl fw_6">Units</h2>
        </div>

        <div className="unit">
          <IconButton
            onClick={() => setShowFilterModal(true)}
            className="filter-btn"
          >
            {/* <img className="w_100" src={FilterFilledWhite} alt="Filter Icon" /> */}
            <img className="w_100" src={FilterGreen} alt="Filter Icon" />
          </IconButton>

          <TextButton
            onClick={() => navigate("add")}
            className="add-btn font_l fw_6"
          >
            + Add Unit
          </TextButton>
        </div>
      </div>

      <div className="search-box mt-4 mb-4">
        <div>
          <div className="search">
            <Searchbar
              setQuery={setSearchQ}
              query={searchQ}
              className="dash-search"
            />
          </div>

          <div className="btn-wrapper">
            <button onClick={() => handleSearch()} disabled={!searchQ}>
              Apply
            </button>

            <button className="btn-clear" onClick={() => handleClear()}>
              Clear
            </button>
          </div>
        </div>
      </div>

      {/* Unit Table */}
      <div className="custom-table-wrapper">
        {units?.units?.length > 0 && (
          <Table
            className="custom-table font_s unit-table"
            borderless
            responsive
          >
            <thead>
              <tr>
                <th>Unit No.</th>
                <th>Name</th>
                <th>Unit Floor</th>
                <th>Floor Plan</th>
                <th>Type</th>
                <th>Unit Status</th>
                <th>Tenant Name</th>
                <th>Sub Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUnits?.map((item, index) => (
                <tr key={index}>
                  {/* Unit No. */}
                  <td>{item?.unit_number}</td>

                  {/* Unit Name. */}
                  <td>{item?.unit_name || "-"}</td>

                  {/* Unit Floor */}
                  <td>{item?.unit_floor || "-"}</td>

                  {/* Floor Plans */}
                  <td>
                    {item?.floor_plan?.url ? (
                      <IconButton
                        onClick={() => onViewFloorPlan(item?.floor_plan)}
                        className="action-button document"
                      >
                        <img
                          className="h_100 v_middle"
                          src={DocumentGreen}
                          alt="Edit Unit"
                        />
                      </IconButton>
                    ) : (
                      "-"
                    )}
                  </td>

                  {/* Type */}
                  <td>{item?.unit_type || "-"}</td>

                  {/* Status */}
                  <td>
                    {item?.is_active ? (
                      <span className="fw_6 font_blue">Active</span>
                    ) : (
                      <span className="fw_6 font_grey">Inactive</span>
                    )}
                  </td>

                  {/* Tenant name */}
                  <td>{item?.tenant !== null ? item?.tenant : "-"}</td>

                  {/* Sub Status */}
                  <td
                    className={`${
                      item?.status === "occupied"
                        ? "font_green"
                        : item?.status === "vacant"
                        ? "font_yellow"
                        : "font_grey"
                    }`}
                  >
                    {item?.is_active ? item?.status.toUpperCase() : "-"}
                  </td>

                  {/* Action Buttons */}
                  <td className="text-start txt-nowrap">
                    {/* View Details */}
                    {item?.is_active && (
                      <IconButton
                        onClick={() => {
                          onViewDetails(item);
                        }}
                        className="action-button"
                      >
                        <img
                          className="h_100 v_middle"
                          src={EyeFilledBlue}
                          alt="View Unit"
                        />
                      </IconButton>
                    )}

                    {/* Edit */}
                    {!item?.is_active && (
                      <IconButton
                        onClick={() => {
                          onEdit(item);
                        }}
                        className="action-button"
                      >
                        <img
                          className="h_100 v_middle"
                          src={EditGreen}
                          alt="Edit Unit"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {units?.units?.length === 0 && (
          <h3 className="not-added-yet text-center font_m font_yellow fw_6">
            No Units Added!
          </h3>
        )}
      </div>

      {/* PAGINATION */}
      {units?.units?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={units?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* Modals */}
      {/* Filter Modal */}
      <UnitTableFilterModal
        show={showFilterModal}
        propertyId={propertyId}
        currentPage={currentPage}
        onClose={() => {
          setShowFilterModal(false);
        }}
      />

      {/* Fileviewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default UnitsTable;
