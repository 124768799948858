/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ProgressSteps from "../../components/ui/other/ProgressSteps/ProgressSteps";
import SuccessModal from "../../components/ui/modal/SuccessModal/SuccessModal";
import WarningModal from "../../components/ui/modal/WarningModal/WarningModal";

// All steps window
import AddingPropertyStep from "./local-components/AddingPropertyStep";
import AddingLicenseStep from "./local-components/AddingLicenseStep";
import AddingManagementModelStep from "./local-components/AddingManagementModelStep";
import AddingOwnerStep from "./local-components/AddingOwnerStep";
import AddingPMCStep from "./local-components/AddingPMCStep";
import AddingFMCStep from "./local-components/AddingFMCStep";
import AddingSMCStep from "./local-components/AddingSMCStep";
import AddingPropertyUnderCommunityStep from "./local-components/AddingPropertyUnderCommunityStep";
import AddOwnerWizard from "./local-components/AddOwnerWizard/AddOwnerWizard";
import AddPMCWizard from "./local-components/AddPMCWizard/AddPMCWizard";
import AddFMCWizard from "./local-components/AddFMCWizard/AddFMCWizard";
import AddSMCWizard from "./local-components/AddSMCWizard/AddSMCWizard";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const AddProperty = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [stepCount, setStepCount] = useState([
    {
      count: 1,
      title: "Property Details",
    },
    {
      count: 2,
      title: "License",
    },
    {
      count: 3,
      title: "Management Model",
    },
  ]);
  const [skipped, setSkipped] = useState(false);
  const [showMissingModal, setShowMissingModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedMgmtModel, setSelectedMgmtModel] = useState(); // 0 - Single, 1 - FMC, 2 - Corporate
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const property = useSelector(getPropertyDetail);
  const [searchParams, setSearchParams] = useSearchParams();
  const resumeStep =
    searchParams.get("resumeStep") !== "continue"
      ? searchParams.get("resumeStep")
      : null;
  /* Other hooks declaration ends */
  console.log(property, "Property Detail");
  /* Component variable declaration / object destructure starts */
  const underCommunity = searchParams.get("under-community") ? true : false;
  const mgmt_model = selectedMgmtModel || (property && property?.mgmt_model);

  /* Component variable declaration / object destructure ends */
  console.log("Current Management Model >> ",mgmt_model)
  /* Component function definition starts */
  // On skipping or cancelling the current step
  const skipStep = () => {
    const nextStep = currentStep + 1;
    switch (currentStep) {
      // Adding Property
      case 1:
        navigate("/provider/home");
        break;

      // Adding Management Model
      case 3:
        navigate("/provider/home");
        break;

      // Adding Owner step
      case 4:
        setShowMissingModal(true);
        break;

      // If "Facility Management" model selected then Adding FMC step, else Adding PMC step
      case 5:
        if (selectedMgmtModel === 1) {
          setShowMissingModal(true);
        } else {
          setShowMissingModal(true);
          setSkipped(true);
          // setCurrentStep(nextStep);
        }
        break;

      // Adding FMC step
      case 6:
        setSkipped(true);
        setCurrentStep(nextStep);
        break;

      // Adding SMC step
      case 7:
        setShowMissingModal(true);
        break;

      default:
        setCurrentStep(nextStep);
        break;
    }
  };

  const handlingURLParams = () => {
    if (currentStep >= 2) {
      const p = {};
      p["resumeStep"] = "continue";
      if (searchParams.get("property_id")) {
        p["property_id"] = searchParams.get("property_id");
      }
      setSearchParams(p);
    }
  };

  // On moving to next step
  const nextStep = () => {
    const nextStep = currentStep + 1;
    handlingURLParams();
    if (searchParams.get("property_id")) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(
            fetchPropertyDetailByID({
              property_id: searchParams.get("property_id"),
            })
          );
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }

    switch (currentStep) {
      // Adding Owner step
      case 4:
        if (mgmt_model === 0 || mgmt_model === 1) {
          setShowSuccessModal(true);
        } else {
          setCurrentStep(nextStep);
        }
        break;

      // If "Facility Management" model selected then Adding FMC step, else Adding PMC step
      case 5:
        if (mgmt_model === 1) {
          setShowSuccessModal(true);
        } else {
          setCurrentStep(nextStep);
        }
        break;

      // Adding SMC step
      case 7:
        if (mgmt_model === 2) {
          if (skipped) {
            setShowMissingModal(true);
          } else {
            setShowSuccessModal(true);
          }
        } else {
          setCurrentStep(nextStep);
        }
        break;

      default:
        setCurrentStep(nextStep);
    }
  };

  // On closing success modal and warning modal
  const onModalClose = () => {
    setShowSuccessModal(false);
    setShowMissingModal(false);
    navigate("/");
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On selecting Management Model to manage property
  useEffect(() => {
    switch (selectedMgmtModel) {
      case 0:
        setStepCount([
          ...stepCount,
          {
            count: 4,
            title: "Add Owner",
          },
        ]);
        break;
      case 1:
        setStepCount([
          ...stepCount,
          {
            count: 4,
            title: "Add Facility Management Company",
          },
        ]);
        break;
      case 2:
        setStepCount([
          ...stepCount,
          {
            count: 4,
            title: "Add Owner",
          },
          {
            count: 5,
            title: "Add Property Management Company",
          },
          {
            count: 6,
            title: "Add Facility Management Company",
          },
          {
            count: 7,
            title: "Add Security Management Company",
          },
        ]);
        break;
      default:
        setStepCount(stepCount);
    }
  }, [selectedMgmtModel]);

  useEffect(() => {
    if (searchParams.get("property_id")) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(
            fetchPropertyDetailByID({
              property_id: searchParams.get("property_id"),
            })
          );
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [searchParams]);

  useEffect(() => {
    const resumeFrom =
      resumeStep && property && resumeStep !== "continue" ? resumeStep : null;
    const propertyStep =
      searchParams.get("property_id") && property ? property?.wizard : null;
    const renderStep = propertyStep || resumeFrom;

    if (renderStep) {
      switch (renderStep) {
        case GLOBAL.PROP_STEP.LICENSE:
          setCurrentStep(2);
          break;
        case GLOBAL.PROP_STEP.MGMT:
          setCurrentStep(3);
          break;
        case GLOBAL.PROP_STEP.OWNER:
          setCurrentStep(4);
          break;
        case GLOBAL.PROP_STEP.PMC:
          setCurrentStep(5);
          break;
        case GLOBAL.PROP_STEP.FMC:
          if (property.mgmt_model === GLOBAL.MGMT_MODEL.T2) {
            setCurrentStep(4);
          } else {
            setCurrentStep(6);
          }
          break;
        case GLOBAL.PROP_STEP.SMC:
          setCurrentStep(7);
          break;
      }
    }
  }, [property, resumeStep]);
  /* Component useEffect ends */
  console.log(currentStep, "CUSRRENT STEP");
  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="add-property" />
      <div className="add-property-page-wrapper-main">
        <div className="container_sm">
          <ProgressSteps steps={stepCount} currentStep={currentStep} />

          {/* Wizard */}
          {/* Add Property Step */}
          {currentStep === 1 && (
            <>
              {!underCommunity && (
                <AddingPropertyStep
                  onStepSuccess={nextStep}
                  onCancelStep={skipStep}
                />
              )}
              {underCommunity && (
                <AddingPropertyUnderCommunityStep
                  onStepSuccess={nextStep}
                  onCancelStep={skipStep}
                />
              )}
            </>
          )}

          {/* Add License Step */}
          {currentStep === 2 && (
            <>
              <AddingLicenseStep
                onStepSuccess={nextStep}
                onCancelStep={skipStep}
                onBack={() => navigate(-1)}
              />
            </>
          )}

          {/* Add Management Model Step */}
          {currentStep === 3 && (
            <>
              <AddingManagementModelStep
                setSelectedMgmtModel={setSelectedMgmtModel}
                onStepSuccess={nextStep}
                onCancelStep={skipStep}
              />
            </>
          )}

          {/* Add Owner Step */}
          {currentStep === 4 && (
            <>
              {mgmt_model === 1 ? (
                <AddFMCWizard onSuccess={nextStep} onCancel={skipStep} />
              ) : (
                <AddOwnerWizard onSuccess={nextStep} onCancel={skipStep} />
              )}
            </>
          )}

          {/* If Management Model Chosen is "Facility Management Company", then Add FMC Step; else Add PMC Step */}
          {currentStep === 5 && (
            <>
              {mgmt_model === 1 && (
                <AddFMCWizard onSuccess={nextStep} onCancel={skipStep} />
              )}
              {mgmt_model === 2 && (
                <AddPMCWizard onSuccess={nextStep} onCancel={skipStep} />
              )}
            </>
          )}

          {/* Add FMC Step */}
          {currentStep === 6 && (
            <>
              <AddFMCWizard onSuccess={nextStep} onCancel={skipStep} />
            </>
          )}

          {/* Add SMC Step */}
          {currentStep === 7 && (
            <>
              <AddSMCWizard onSuccess={nextStep} onCancel={skipStep} />
            </>
          )}
        </div>

        {/* Modals */}
        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          title="Property Added"
          description="Property Added sucessfully. After closing this you will be redirected to Home."
          onClose={onModalClose}
        />

        {/* Warning Modal */}
        <WarningModal
          showModal={showMissingModal}
          title="Missing Items"
          description="Property Added sucessfully, but missing the minimum required roles to operate the property."
          onClose={onModalClose}
        />
      </div>
    </>
  );
};
/* Component ends */

export default AddProperty;
