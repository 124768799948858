/**
 *
 */

import React, { useEffect, useState, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { initialValues, validations, formReset } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useSearchParams, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { inviteMember } from "../../../../setup/store/slices/teamSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import { getUser } from "../../../../setup/store/slices/authSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
  updateProperty,
} from "../../../../setup/store/slices/propertySlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice.js";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ErrorModal from "../../../../components/ui/modal/ErrorModal/ErrorModal";
import CustomDateInput from "../../../../components/ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomFileInput from "../../../../components/ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../../components/ui/button/TextButton/TextButton";
import CustomCurrencyInput from "../../../../components/ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomRadioInput from "../../../../components/ui/input/CustomRadioInput/CustomRadioInput";
import SearchForAccount from "../../../../components/ui/other/SearchForAccount/SearchForAccount";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddFMCWizard = (props) => {
  /* Props destructuring starts */
  const { onCancel, onSuccess } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [fmcRole, setFmcRole] = useState();
  const [search, setSearch] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);

  // useRef
  const contract_copy_img = useRef(null);
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Redux Slice
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRoles);
  const property = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_id = searchParams.get("property_id") || propertyId;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSkipped = async () => {
    const payload = {
      id: property._id,
      wizard: GLOBAL.PROP_STEP.DONE,
    };
    try {
      const result = await dispatch(updateProperty(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setTimeout(() => {
            setShowErrModal(true);
          }, 300);
          break;
        case GLOBAL.FULFILLED:
          onCancel();
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const onInviteSuccess = async () => {
    let wizard;
    switch (property.mgmt_model) {
      case GLOBAL.MGMT_MODEL.T2:
        wizard = GLOBAL.PROP_STEP.DONE;
        break;
      case GLOBAL.MGMT_MODEL.T3:
        wizard = GLOBAL.PROP_STEP.SMC;
        break;
    }
    const payload = {
      id: property._id,
      wizard,
    };
    try {
      const result = await dispatch(updateProperty(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setTimeout(() => {
            setShowErrModal(true);
          }, 300);
          break;
        case GLOBAL.FULFILLED:
          onSuccess();
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // On inviting searched FMC
  const inviteSearched = (searchResult) => {
    setInitialFormValues({
      first_name: searchResult.first_name,
      last_name: searchResult.last_name,
      profession: "Facility Management Company",
      email: searchResult.email,
      employee_id: "",
      contract_number: "",
      contract_value: "",
      mobile: searchResult.countrycode + searchResult.mobile,
      business_name: searchResult.business_name,
      contract_copy_img: "",
    });
    customPhoneNum.current = {
      code: searchResult.countrycode,
      num: searchResult.mobile,
    };

    setSearch(false);
    setMemberSelected(true);
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values, customPhoneNum, contract_copy_img);
  };

  // Handling on submit
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      role_id: fmcRole._id,
      property_id: property_id,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      profession: "Facility Management Company",
      email: values.email.toLowerCase().trim(),
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      employee_id: values.employee_id,
      contract_number: values.contract_number,
      contract_start_date: startDate,
      contract_end_date: endDate,
      contract_value: values.contract_value,
      business_name: values.business_name,
      contract_copy_img: contract_copy_img.current,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(inviteMember(payload));
      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onInviteSuccess();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all user roles on initial load
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchPropertyDetailByID({ property_id }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting FMC role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d.name == GLOBAL.USER_ROLE.FMC)[0];
    setFmcRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-FMC-wrapper-main">
      <h2 className="heading text-center font_xxl mb-5">
        Add Facility Management Company
      </h2>
      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Radio select if there is existing FMC or not */}
                <div className="form-group form-group-full w_100">
                  <p className="font_m font_grey w_100">
                    Adding existing FMC ?
                  </p>
                  <CustomRadioInput
                    label="Yes"
                    onSelecting={() => {
                      setSearch(true);
                    }}
                    isSelected={search || memberSelected}
                  />
                  <CustomRadioInput
                    label="No"
                    onSelecting={() =>
                      formReset(
                        setSearch,
                        setInitialFormValues,
                        setStartDate,
                        setEndDate,
                        setMemberSelected,
                        customPhoneNum,
                        contract_copy_img
                      )
                    }
                    isSelected={!search && !memberSelected}
                  />
                </div>

                {/* Creating new FMC */}
                {!search && (
                  <>
                    {/* Input for First Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name="first_name"
                        label="First Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.first_name}
                        errors={errors.first_name}
                        value={values.first_name}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Last Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name="last_name"
                        label="Last Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.last_name}
                        errors={errors.last_name}
                        value={values.last_name}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Profession */}
                    <div className="form-group">
                      <CustomTextInput
                        name="profession"
                        label="Profession"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.profession}
                        errors={errors.profession}
                        value={values.profession}
                        readOnly={true}
                      />
                    </div>

                    {/* Input for Company Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name="business_name"
                        label="Company Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.business_name}
                        errors={errors.business_name}
                        value={values.business_name}
                      />
                    </div>

                    {/* Input for Email */}
                    <div className="form-group">
                      <CustomTextInput
                        name="email"
                        label="Email"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.email}
                        errors={errors.email}
                        value={values.email}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Phone */}
                    <div className="form-group">
                      <CustomPhoneInput
                        name="mobile"
                        label={"Phone Number"}
                        customPhoneNum={customPhoneNum}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.mobile}
                        countryCodeEditable={true}
                        errors={errors.mobile}
                        touched={touched.mobile}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Employee ID */}
                    <div className="form-group">
                      <CustomTextInput
                        name="employee_id"
                        label="Employee ID"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.employee_id}
                        errors={errors.employee_id}
                        value={values.employee_id}
                      />
                    </div>

                    {/* Input for Contract Number */}
                    <div className="form-group">
                      <CustomTextInput
                        name="contract_number"
                        label="Contract Number"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.contract_number}
                        errors={errors.contract_number}
                        value={values.contract_number}
                      />
                    </div>

                    {/* Input for Start Date */}
                    <div className="form-group">
                      <CustomDateInput
                        label="Start Date"
                        value={startDate}
                        customOnchange={(date) => setStartDate(date)}
                      />
                    </div>

                    {/* Input for End Date */}
                    <div className="form-group">
                      <CustomDateInput
                        label="End Date"
                        value={endDate}
                        customOnchange={(date) => setEndDate(date)}
                      />
                    </div>

                    {/* Input for Contract Value */}
                    <div className="form-group">
                      <CustomCurrencyInput
                        name="contract_value"
                        label={`Contract Value (${property?.currency})`}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.contract_value}
                        errors={errors.contract_value}
                        value={values.contract_value}
                        currency={user?.currency}
                      />
                    </div>

                    {/* Input for Contract Copy */}
                    <div className="form-group">
                      <CustomFileInput
                        label="Upload Copy Contract"
                        name="contract_copy_img"
                        files={contract_copy_img}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.contract_copy_img}
                        touched={touched.contract_copy_img}
                        values={values.contract_copy_img}
                        formikState={false}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="button-wrapper text-center">
                {!search && (
                  <>
                    <CustomButton type="submit" size="l">
                      Add
                    </CustomButton>
                    <TextButton
                      type="button"
                      onClick={onSkipped}
                      className="font_m fw_6"
                    >
                      Cancel
                    </TextButton>
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
        {/* Searching for existing FMC */}
        {search && (
          <>
            <SearchForAccount
              roleType={fmcRole}
              title="Search Owner"
              onInvite={inviteSearched}
            />
          </>
        )}

        {/* Modals */}
        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          title="Error"
          description={message}
          onClose={() => {
            setShowErrModal(false);
          }}
        />
      </div>
    </div>
  );
};
/* Component ends */

export default AddFMCWizard;
