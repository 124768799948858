/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { pathNamePopped } from "../../setup/utils/global-helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchRoutineTaskGeneratedTicketsList,
  fetchRoutineTasks,
  getRoutineTaskLists,
  getRoutineTaskTicketLists,
} from "../../setup/store/slices/routineSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
// import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../components/ui/other/TabButton/TabButton";
import ActiveTasks from "../../components/module/RoutineRequest/RoutineTaskTable/TasksTable/ActiveTasks";
import ExpiredTasks from "../../components/module/RoutineRequest/RoutineTaskTable/TasksTable/ExpiredTasks";
import OpenTasks from "../../components/module/RoutineRequest/RoutineTaskTable/RequestsTable/OpenTasks";
import ClosedTasks from "../../components/module/RoutineRequest/RoutineTaskTable/RequestsTable/ClosedTasks";

import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";

const RoutineRequest = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [buttonLists, setButtonLists] = useState([]);

  const [subButtonLists, setSubButtonLists] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { tab, subTab, propertyId } = useParams();

  const routineTaskList = useSelector(getRoutineTaskLists);
  const routineTaskTicketList = useSelector(getRoutineTaskTicketLists);
  const propertyDetail = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "task", label: "Task" },
    { key: "request", label: "Request" },
  ];

  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isThirdPM = localStorage.getItem("user_role") === "Third Party Manager";

  const isTPT = localStorage.getItem("user_role") === "Third Party Technician";
  const isMT = localStorage.getItem("user_role") === "Maintenance Technician";
  const isAcc = localStorage.getItem("user_role") === "Accountant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onTabSelect = (key) => {
    const newPathname = pathNamePopped(pathname, 2);
    if (key === "task") {
      navigate(`${newPathname}/${key}/active`);
      setCurrentPage(1);
    } else {
      navigate(`${newPathname}/${key}/open`);
    }
  };

  const onSubTabSelect = (key) => {
    const newPathname = pathNamePopped(pathname, 1);
    navigate(`${newPathname}/${key}`);
    setCurrentPage(1);
    // setCurrentSubKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (isFM || isThirdPM) {
      setButtonLists([
        { key: "task", label: "Task" },
        { key: "request", label: "Request" },
      ]);
      if (tab === "task") {
        setSubButtonLists([
          { key: "active", label: "Active" },
          { key: "expired", label: "Expired" },
        ]);
      } else {
        setSubButtonLists([
          { key: "open", label: "Open" },
          { key: "closed", label: "Closed" },
        ]);
      }
    } else {
      setButtonLists([
        // { key: "task", label: "Task" },
        { key: "request", label: "Request" },
      ]);
      if (tab === "request") {
        //   setSubButtonLists([
        //     { key: "active", label: "Active" },
        //     { key: "expired", label: "Expired" },
        //   ]);
        // } else {
        setSubButtonLists([
          { key: "open", label: "Open" },
          { key: "closed", label: "Closed" },
        ]);
      }
    }
  }, [tab, isFM, isThirdPM]);

  useEffect(() => {
    if (tab === "task") {
      if (subTab === "active") {
        let payload = {
          property_id: propertyId,
          status: "Active",
          page: currentPage,
        };
        dispatch(fetchRoutineTasks(payload));
      } else if (subTab === "expired") {
        let payload = {
          property_id: propertyId,
          status: "Expired",
          page: currentPage,
        };
        dispatch(fetchRoutineTasks(payload));
      }
    } else if (tab === "request") {
      if (subTab === "open") {
        let payload = {
          property_id: propertyId,
          status: "Open",
          page: currentPage,
        };

        dispatch(fetchRoutineTaskGeneratedTicketsList(payload));
      } else {
        let payload = {
          property_id: propertyId,
          status: "Closed",
          page: currentPage,
          // requester: (isAcc || isMT || isTPT) && user?._id,
        };
        if (isAcc || isMT || isTPT) {
          payload["requester"] = user?._id;
        }
        dispatch(fetchRoutineTaskGeneratedTicketsList(payload));
      }
    }
  }, [tab, subTab, propertyId, currentPage]);

  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));

          await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  console.log(user, "[USER]", isAcc, "||", isMT, "||", isTPT);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <>
      <BreadCrumb
        type="routineRequests"
        // from={pathname?.includes("ratingAndReview") ? "ratingAndReview" : ""}
      />
      <div className="routine-wrapper">
        <div className="routine-heading">
          <h2 className="font_xxl fw_6">Routine Requests</h2>
          {tab === "task" && subTab === "active" && (isFM || isThirdPM) && (
            <Link to="add" className="font_m fw_6 d_block link">
              + Create Task
            </Link>
          )}
        </div>

        {/* <Searchbar /> */}

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonLists}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "task" ? (
          <>
            <TabButton
              buttonList={subButtonLists}
              currentSubKey={subTab}
              onSelect={onSubTabSelect}
            />

            {subTab === "active" ? (
              <ActiveTasks
                taskLists={routineTaskList?.routine}
                totalCount={routineTaskList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                property={propertyDetail}
              />
            ) : (
              <ExpiredTasks
                taskLists={routineTaskList?.routine}
                totalCount={routineTaskList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                property={propertyDetail}
              />
            )}
          </>
        ) : (
          <>
            <TabButton
              buttonList={subButtonLists}
              currentSubKey={subTab}
              onSelect={onSubTabSelect}
            />

            {subTab === "open" ? (
              <OpenTasks
                ticketLists={routineTaskTicketList?.routine}
                totalCount={routineTaskTicketList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              <ClosedTasks
                ticketLists={routineTaskTicketList?.routine}
                totalCount={routineTaskTicketList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
/* Component End */

export default RoutineRequest;
