/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../../setup/store/slices/authSlice";
import { deleteAnnoucement } from "../../../../setup/store/slices/annoucementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DeleteRed, EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import IconButton from "../../../ui/button/IconButton/IconButton";
import Pagination from "../../../ui/other/Pagination/Pagination";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AnnoucementsTable = (props) => {
  /* Props destructuring starts */
  const { annoucementListData, totalCount, currentPage, setCurrentPage } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [cookies, setCookie] = useCookies(["openedItems"]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [annoucementId, setAnnoucementId] = useState("");
  const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const isClient = localStorage.getItem("user_role") === "Client";
  const isOwner = localStorage.getItem("user_role") === "Owner";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleViewReport = async (id) => {
    // Add the clicked item to cookies
    const openedItems = cookies?.openedItems || [];
    if (!openedItems?.includes(id)) {
      openedItems.push(id);
      setCookie("openedItems", openedItems);
    }

    navigate(`${id}`, { state: role });
  };

  // On delete Annoucement
  const onDelete = (item) => {
    setAnnoucementId(item?._id);
    setModalDesc("Are you sure you want to delete this Annoucement ?");
    setShowConfirmationModal(true);
  };

  // On confirming delete
  const confirmDelete = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      _id: annoucementId,
    };

    console.log(payload, "[PAY]");

    // Backend response. Try, catch
    try {
      const result = await dispatch(deleteAnnoucement(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Annoucement successfully deleted");
          setShowConfirmationModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  useEffect(() => {
    // Update highlighted incidentList based on cookies
    const openedItems = cookies?.openedItems || [];
    const newItems = annoucementListData?.filter(
      (item) => !openedItems.includes(item._id)
    );

    setHighlightedItems(newItems);
  }, [annoucementListData, cookies?.openedItems]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper annoucement-table-wrapper">
        {annoucementListData?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}

            {annoucementListData?.length > 0 ? (
              <tbody>
                {annoucementListData?.map((item) => (
                  <>
                    {highlightedItems?.includes(item) ? (
                      <>
                        <span className="font_xxl new-label"></span>
                        <tr key={item._id}>
                          {/* Title */}
                          <td>{item?.title}</td>

                          {/* Description */}
                          <td>
                            <div className="main-desc">{item?.description}</div>
                          </td>

                          {/* Start Date */}
                          <td>
                            {moment(item?.start_date).format(DATE_FORMAT)}
                          </td>

                          {/* End Date */}
                          <td>{moment(item?.end_date).format(DATE_FORMAT)}</td>

                          {/* Actions */}
                          <td className="text-start txt-nowrap">
                            <IconButton
                              onClick={() => handleViewReport(item?._id)}
                              className="action-button"
                            >
                              <img
                                src={EyeFilledBlue}
                                className="h_100"
                                alt="View Details"
                              />
                            </IconButton>

                            {new Date(item?.end_date) >
                              new Date().setHours(0, 0, 0, 0) &&
                              (isClient || isOwner) && (
                                <IconButton
                                  className="action-button"
                                  onClick={() => onDelete(item)}
                                >
                                  <img
                                    src={DeleteRed}
                                    className="h_100"
                                    alt="View Details"
                                  />
                                </IconButton>
                              )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr key={item._id}>
                          {/* Title */}
                          <td>{item?.title}</td>

                          {/* Description */}
                          <td>
                            <div className="main-desc">{item?.description}</div>
                          </td>

                          {/* Start Date */}
                          <td>
                            {moment(item?.start_date).format(DATE_FORMAT)}
                          </td>

                          {/* End Date */}
                          <td>{moment(item?.end_date).format(DATE_FORMAT)}</td>

                          {/* Actions */}
                          <td className="text-start txt-nowrap">
                            <IconButton
                              onClick={() => handleViewReport(item?._id)}
                              className="action-button"
                            >
                              <img
                                src={EyeFilledBlue}
                                className="h_100"
                                alt="View Details"
                              />
                            </IconButton>
                            {new Date(item?.end_date) >
                              new Date().setHours(0, 0, 0, 0) &&
                              (isClient || isOwner) && (
                                <IconButton
                                  className="action-button"
                                  onClick={() => onDelete(item)}
                                >
                                  <img
                                    src={DeleteRed}
                                    className="h_100"
                                    alt="View Details"
                                  />
                                </IconButton>
                              )}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                ))}
              </tbody>
            ) : (
              ""
            )}
          </Table>
        )}

        {annoucementListData?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {totalCount !== null && annoucementListData?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title="Confirm Delete"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={confirmDelete}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Deleted"
        onClose={() => setShowSuccessModal(false)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </>
  );
};
/* Component Ends */
export default AnnoucementsTable;
