/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { renderRoleAbbr } from "../helper";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { WarningRoundedOutlineYellow } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Missing = (props) => {
  /* Props destructuring starts */
  const { isT1, isT2, isT3, missingMember, members } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [missingList, setMissingList] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const isTPCavailable = () => {};

  const t1MissingTeam = () => {
    const missingText = [];
    if (!members["Owner"]) {
      missingText.push("Missing Owner");
    }
    setMissingList(missingText);
  };

  const t2MissingTeam = () => {
    const missingText = [];
    const fmc =
      members["Facility Management Company"]?.length > 0
        ? members["Facility Management Company"]
        : [];

    const tpc =
      members["Third-Party Company"]?.length > 0
        ? members["Third-Party Company"]
        : [];

    let noFMCOnboarded = true;
    let noTPCOnboarded = true;

    for (let i = 0; i < fmc.length; i++) {
      if (
        fmc[i]?.status === GLOBAL.USER_STATUS.ACTIVE
        // ||  fmc[i]?.status === GLOBAL.USER_STATUS.SUSPENDED
      ) {
        noFMCOnboarded = false;
        let noFMOnboarded = true;
        let noMTOnboarded = true;

        const fm =
          fmc[i]?.subMembers && fmc[i]?.subMembers["Facility Manager"]
            ? fmc[i]?.subMembers["Facility Manager"]
            : [];

        const mt =
          fmc[i]?.subMembers && fmc[i]?.subMembers["Maintenance Technician"]
            ? fmc[i]?.subMembers["Maintenance Technician"]
            : [];

        for (let j = 0; j < fm.length; j++) {
          if (
            fm[j]?.status === GLOBAL.USER_STATUS.ACTIVE ||
            fm[j]?.status === GLOBAL.USER_STATUS.SUSPENDED
          ) {
            noFMOnboarded = false;
            break;
          }
        }

        for (let j = 0; j < mt.length; j++) {
          if (
            mt[j]?.status === GLOBAL.USER_STATUS.ACTIVE ||
            mt[j]?.status === GLOBAL.USER_STATUS.SUSPENDED
          ) {
            noMTOnboarded = false;
            break;
          }
        }

        if (noFMOnboarded) {
          missingText.push(
            "Missing Facility Manager for " + fmc[i]?.business_name
          );
        }

        if (noMTOnboarded) {
          missingText.push(
            "Missing Maintenance Technician for " + fmc[i]?.business_name
          );
        }

        break;
      }
    }

    if (noFMCOnboarded) {
      missingText.push("Missing Facility Management Company");
      missingText.push("Missing Facility Manager");
      missingText.push("Missing Maintenance Technician");
    }

    for (let i = 0; i < tpc.length; i++) {
      if (
        tpc[i]?.status === GLOBAL.USER_STATUS.ACTIVE ||
        tpc[i]?.status === GLOBAL.USER_STATUS.SUSPENDED
      ) {
        let noTPMOnboarded = true;
        let noTPTOnboarded = true;

        const tpm =
          tpc[i]?.subMembers && tpc[i]?.subMembers["Third Party Manager"]
            ? tpc[i]?.subMembers["Third Party Manager"]
            : [];

        const tpt =
          tpc[i]?.subMembers && tpc[i]?.subMembers["Third Party Technician"]
            ? tpc[i]?.subMembers["Third Party Technician"]
            : [];

        for (let j = 0; j < tpm.length; j++) {
          if (
            tpm[j]?.status === GLOBAL.USER_STATUS.ACTIVE ||
            tpm[j]?.status === GLOBAL.USER_STATUS.SUSPENDED
          ) {
            noTPMOnboarded = false;
            break;
          }
        }

        for (let j = 0; j < tpt.length; j++) {
          if (
            tpt[j]?.status === GLOBAL.USER_STATUS.ACTIVE ||
            tpt[j]?.status === GLOBAL.USER_STATUS.SUSPENDED
          ) {
            noTPTOnboarded = false;
            break;
          }
        }

        if (noTPMOnboarded) {
          missingText.push(
            "Missing Third Party Manager for " + tpc[i]?.business_name
          );
        }

        if (noTPTOnboarded) {
          missingText.push(
            "Missing Third Party Technician for " + tpc[i]?.business_name
          );
        }

        break;
      }
    }

    setMissingList(missingText);
  };

  const t3MinTeam = () => {};

  const getCompanyNameByEmployee = (role) => {};
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (members) {
      if (isT1) {
        t1MissingTeam();
      } else if (isT2) {
        t2MissingTeam();
      } else if (isT3) {
      }
    }
  }, [members]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (missingList?.length > 0)
    return (
      <div className="missing-wrapper">
        <h3 className="font_m font_yellow">
          This Property don't have Team Members of following role assigned to it
          for full functioning of the app. Please onboard them to use all the
          features of the platform :-
        </h3>

        <ul className="missing-list">
          {missingList.map((e, i) => (
            <>
              <li className="missing-item" key={i}>
                <div className="icon-wrapper">
                  <img
                    className="missing-icon w-100"
                    src={WarningRoundedOutlineYellow}
                    alt="Warning"
                  />{" "}
                </div>
                <p className="missing-role font_s font_yellow fw_5">
                  {/* Missing {renderRoleAbbr(e)} */}
                  {e}
                </p>
              </li>
            </>
          ))}
        </ul>
      </div>
    );
};
/* Component ends */

export default Missing;
