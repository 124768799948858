/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import TenantDetailTables from "../../../components/module/Tenant/TenantDetailTables/TenantDetailTables";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../setup/store/slices/propertySlice";
import { useDispatch, useSelector } from "react-redux";

/* Import local pages and component ends */

/* Component starts */
const DetailTables = (props) => {
  /* Props destructuring starts */
  const { unitInfo, propertyId } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("");
  const [buttonLists, setButtonLists] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const propertyData = useSelector(getPropertyDetail);
  const prevTenant = pathname.split("/");
  /* Other hooks declaration ends */

  console.log(propertyData, "[PROP]");

  /* Component variable declaration / object destructure starts */

  const tabButtonList = [
    { key: "contract", label: "Contract" },
    { key: "rent", label: "Rent" },
    { key: "deposits", label: "Deposits" },
    { key: "invoices", label: "Invoices" },
    // { key: "otherDetails", label: "OtherDetails" },
    // { key: "tenantHistory", label: "TenantHistory" },
    // { key: "facilities", label: "Facilities" },
    { key: "violations", label: "Violations" },
    { key: "maintenance", label: "Maintenance" },
    { key: "security", label: "Security" },
    // { key: "notifications", label: "Notifications" },
    { key: "onboarding", label: "Onboarding" },
    // { key: "requests", label: "Requests" },
    { key: "unitDetails", label: "UnitDetails" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onTabSelect = (key) => {
    setCurrentKey(key);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (propertyData?.mgmt_model === 0) {
      if (prevTenant?.includes("previous-tenants")) {
        setButtonLists([
          { key: "contract", label: "Contract" },
          { key: "rent", label: "Rent" },
          { key: "deposits", label: "Deposits" },
          { key: "invoices", label: "Invoices" },
          // { key: "otherDetails", label: "OtherDetails" },
          // { key: "tenantHistory", label: "TenantHistory" },
          // { key: "facilities", label: "Facilities" },
          { key: "violations", label: "Violations" },
          { key: "maintenance", label: "Maintenance" },
          { key: "security", label: "Security" },
          // { key: "notifications", label: "Notifications" },
          { key: "onboarding", label: "Onboarding" },
          { key: "unitDetails", label: "UnitDetails" },
          { key: "move-out", label: "Move Out" },
        ]);

        setCurrentKey("contract");
      } else {
        setButtonLists([
          { key: "contract", label: "Contract" },
          { key: "rent", label: "Rent" },
          { key: "deposits", label: "Deposits" },
          { key: "invoices", label: "Invoices" },
          // { key: "otherDetails", label: "OtherDetails" },
          // { key: "tenantHistory", label: "TenantHistory" },
          // { key: "facilities", label: "Facilities" },
          { key: "violations", label: "Violations" },
          { key: "maintenance", label: "Maintenance" },
          { key: "security", label: "Security" },
          // { key: "notifications", label: "Notifications" },
          { key: "onboarding", label: "Onboarding" },
          // { key: "requests", label: "Requests" },
          { key: "unitDetails", label: "UnitDetails" },
        ]);
      }
      setCurrentKey("contract");
    } else if (propertyData?.mgmt_model === 1) {
      if (prevTenant?.includes("previous-tenants")) {
        setButtonLists([
          { key: "invoices", label: "Invoices" },
          { key: "maintenance", label: "Maintenance" },
          { key: "unitDetails", label: "UnitDetails" },
          // { key: "move-out", label: "Move Out" },
        ]);
        setCurrentKey("invoices");
      } else {
        setButtonLists([
          { key: "invoices", label: "Invoices" },
          { key: "maintenance", label: "Maintenance" },
          { key: "unitDetails", label: "UnitDetails" },
        ]);
      }
      setCurrentKey("invoices");
    }
  }, [propertyData]);

  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-table-wrapper">
      <TabButton
        className="detail-tab-btn"
        buttonList={buttonLists}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />

      <TenantDetailTables currentKey={currentKey} unitInfo={unitInfo} />
    </div>
  );
};
/* Component ends */

export default DetailTables;
